<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    width="1200"
    scrollable
    >
    <template v-slot:activator="{ on, attrs }">
      <v-card
        >
        <v-card-title
          class="justify-center"
          >
          Condición de Pago
        </v-card-title>
        <v-card-text
          >
          <div
            style="height: 66px"
            >
            Modifica el campo Condición de Pago asignado a cada PO.
          </div>

          <div
            class="text-right mt-3"
            >
            <v-btn
              depressed
              color="transparent"
              v-on="on"
              v-bind="attrs"
              >
              <img
                src="@/assets/icons/tiantar-upload-excel.png"
                height="36px"
                width="36px"
                ></img>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </template>

    <v-card>
      <v-card-title>
        Carga masiva Condición de Pago
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="goToReport('batch_payment_condition')"
          depressed
          class="rounded-lg"
          >
          Descargar plantilla
        </v-btn>
      </v-card-title>

      <v-card-text>
        <div
          class="text-subtitle-1 text-center mb-3"
          >
          Nueva importación
        </div>

        <div
          class="d-flex flex-row"
          style="gap: 8px"
          >
          <v-file-input
            outlined
            solo
            flat
            dense
            accept=".xlsx"
            v-model="file"
            label="Archivo"
            hide-details
            ></v-file-input>

          <v-btn
            class="rounded-lg py-5"
            depressed
            color="primary"
            @click="perform"
            :disabled="!file || loading"
            :loading="loading"
            >
            Cargar
          </v-btn>
        </div>

        <v-divider
          class="my-3"
          ></v-divider>
        
        <div>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    ID
                  </th>
                  <th class="text-left">
                    Archivo
                  </th>
                  <th class="text-left">
                    Estado
                  </th>
                </tr>
              </thead>
              <tbody
                :key="band"
                >
                <template
                  v-for="(importation, i) in importations"
                  >
                  <tr
                    style="cursor: pointer"
                    :class="importation.lines.length ? 'red lighten-4' : 'success lighten-4'"
                    @click="showDetail(i)"
                    >
                    <td
                      style="width: 70px"
                      >
                      {{ importation.id }}
                    </td>
                    <td
                      style="width: 300px"
                      >{{ importation.filename }}</td>
                    <td>{{ importation.lines.length ? `Con errores (${importation.lines.length})` : 'Exitosa' }}</td>
                  </tr>

                  <template
                    v-if="show[i]"
                    >
                    <template
                      v-if="importation.lines.length"
                      >
                      <tr
                        v-for="line in importation.lines"
                        >
                        <td></td>
                        <td>Línea: {{ line.line }}</td>
                        <td>{{ line.message }}</td>
                      </tr>
                    </template>
                  </template>
                </template>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex' 
import { PaymentConditionImport } from '@/graphql/mutations/importations'
import { Importations } from '@/graphql/queries/importations'

export default {
  data: () => ({
    loading: false,
    importations: [],
    dialog: false,
    file: null,
    band: 0,
    show: []
  }),

  created () {
    this.fetchImportations ()
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  methods: {
    perform () {
      this.loading = true

      this.$apollo.mutate({
        mutation: PaymentConditionImport,
        variables: {
          input: {
            id: this.$route.params.id,
            file: this.file
          }
        }
      }).then ( res => {
        this.loading = false
        this.fetchImportations()
      })
    },

    fetchImportations () {
      this.$apollo.query({
        query: Importations,
        variables: {
          id: this.$route.params.id,
          importationType: 'payment_condition'
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.importations = res.data.importations
        this.importations.forEach ( (importation) => {
          this.show.push(false)
        })
      })
    },

    showDetail (i) {
      this.show[i] = !this.show[i]
      this.band += 1
    },

    goToReport(report) {
      window.open(`${process.env.VUE_APP_RAILS_URL}/${report}_report/${this.$route.params.id}?uid=${this.currentUser.id}`)
    }
  }
}
</script>
